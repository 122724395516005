import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCarouselsAsync = createAsyncThunk(
  "carousel/getCarouselsAsync",
  async () => {
    const response = await axios.get("/api/carousel");

    if (response.status === 200) {
      const carousels = response.data.carousel;
      return { carousels };
    }
  }
);

export const createCarouselsAsync = createAsyncThunk(
  "carousel/createCarouselsAsync",
  async (data, thunkApi) => {
    const { token } = thunkApi.getState().users.user;
    const response = await axios.post("/api/carousel", data, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 201) {
      const carousel = response.data;
      return { carousel };
    }
  }
);

export const deleteCarouselsAsync = createAsyncThunk(
  "carousel/deleteCarouselsAsync",
  async (id, thunkApi) => {
    const { token } = thunkApi.getState().users.user;
    const response = await axios.delete(`/api/carousel/${id}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return { id };
    }
  }
);

export const getCampaignAsync = createAsyncThunk(
  "campaign/getCampaignAsync",
  async () => {
    const response = await axios.get("/api/campaign/dashboard");

    if (response.status === 200) {
      const campaigns = response.data;
      return { campaigns };
    }
  }
);

export const createCampaignsAsync = createAsyncThunk(
  "campaign/createCampaignsAsync",
  async (data, thunkApi) => {
    const { token } = thunkApi.getState().users.user;
    const response = await axios.post("/api/campaign", data, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 201) {
      const campaign = response.data;
      return { campaign };
    }
  }
);

export const deleteCampaignsAsync = createAsyncThunk(
  "campaign/deleteCampaignsAsync",
  async (id, thunkApi) => {
    const { token } = thunkApi.getState().users.user;
    const response = await axios.delete(`/api/campaign/${id}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return { id };
    }
  }
);

export const updateCampaignAsync = createAsyncThunk(
  "campaign/updateCampignAsync",
  async (data, thunkApi) => {
    const { token } = thunkApi.getState().users.user;
    const response = await axios.put(`api/campaign/${data.id}`, data, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      const res = response.data;
      return { res };
    }
  }
);

const carouselSlice = createSlice({
  name: "carousel",
  initialState: {
    carousel: [],
    campaign: [],
    loading: false,
    updateSuccess: false,
  },
  reducers: {
    reset: (state) => {
      state.updateSuccess = false;
    },
  },
  extraReducers: {
    [getCarouselsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [getCarouselsAsync.fulfilled]: (state, action) => {
      state.carousel = action.payload.carousels;
      state.loading = false;
    },

    [createCarouselsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [createCarouselsAsync.fulfilled]: (state, action) => {
      state.carousel = [...state.carousel, action.payload.carousel];
      state.loading = false;
    },
    [deleteCarouselsAsync.fulfilled]: (state, action) => {
      state.carousel = state.carousel.filter(
        (c) => c._id !== action.payload.id
      );
    },

    [getCampaignAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [getCampaignAsync.fulfilled]: (state, action) => {
      state.campaign = action.payload.campaigns;
      state.loading = false;
    },

    [createCampaignsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [createCampaignsAsync.fulfilled]: (state, action) => {
      state.campaign = [...state.campaign, action.payload.campaign];
      state.loading = false;
    },

    [deleteCampaignsAsync.fulfilled]: (state, action) => {
      state.campaign = state.campaign.filter(
        (c) => c._id !== action.payload.id
      );
    },
    [updateCampaignAsync.fulfilled]: (state, action) => {
      state.updateSuccess = true;
    },
  },
});

export const { reset } = carouselSlice.actions;

export default carouselSlice.reducer;
