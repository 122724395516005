import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Container, Row, Col, Form, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import LoaderComp from '../../components/LoaderComp';
import Paginate from '../../components/Paginate';

const Customers = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [subscribers, setSubscribers] = useState(null);
  const { user } = useSelector((state) => state.users);
  const [filter, setFilter] = useState(null);
  const getSubscribersList = async () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.get(
      `/api/subscribers?pageNumber=${pageNumber}`,
      config
    );
    if (response.status === 200) {
      setSubscribers(response.data);
      setLoading(false);
    }
  };

  const filterSubscribers = async () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.get(
      `/api/subscribers/list/${filter}?pageNumber=${pageNumber}`,
      config
    );
    if (response.status === 200) {
      setSubscribers(response.data);
      setLoading(false);
    }
  };
  let { pageNumber } = useParams();
  pageNumber = pageNumber || 1;
  useEffect(() => {
    if (filter) {
      filterSubscribers({ pageNumber });
    } else {
      getSubscribersList({ pageNumber });
    }
  }, [pageNumber, filter]);
  if (loading) return <LoaderComp />;
  return (
    <>
      <Container>
        <section>
          <h1>Customers</h1>
        </section>

        <section className='transactionWrapper'>
          <section className='transFilter'>
            <Form.Select
              value={filter}
              aria-label='Default select example'
              onChange={(e) => {
                if (e.target.value === 'low-to-high') {
                  setFilter('low-to-high');
                } else if (e.target.value === 'high-to-low') {
                  setFilter('high-to-low');
                } else {
                  setFilter(null);
                }
              }}
            >
              <option>Sort By</option>
              <option value='low-to-high'>Orders low to high</option>
              <option value='high-to-low'>Orders high to low</option>
            </Form.Select>
          </section>

          <section className='transTable'>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Address</th>
                  <th>Successful Orders</th>
                </tr>
              </thead>
              <tbody>
                {subscribers?.subscribers.map((i) => (
                  <tr key={i._id}>
                    <td>{i.subscriber.name}</td>
                    <td>{i.subscriber.email}</td>
                    <td>{i.subscriber.phone}</td>
                    <td>{i.subscriber.address}</td>
                    <td>{i.orderCount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {!loading && (
              <Paginate
                pages={subscribers.pages}
                page={subscribers.page}
                isAdmin={true}
                list='customers'
                navigate={navigate}
              />
            )}
          </section>
        </section>
      </Container>
    </>
  );
};

export default Customers;
