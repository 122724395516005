import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBlogAsync } from "../../redux/blogSlice";
const Blogs = () => {
  const dispatch = useDispatch();
  const blogs = useSelector((state) => state.blogs.blogs);
  useEffect(() => {
    dispatch(getBlogAsync());
  }, []);

  return (
    <div>
      <Container>
        <section className="mt-5">
          <h1>Blogs</h1>
        </section>

        <section className="SliderWrapper">
          <Link to={"/blogs/add"} className="bton bton--md bton--primary">
            Add Blogs
          </Link>
          <ul>
            {blogs.map((blog) => (
              <>
                <li>{blog.title}</li>
                <li>
                  <img
                    src={`${process.env.REACT_APP_IMAGE_PREFIX}${blog.image}`}
                  />
                </li>
                <li>
                  <Link to={`/blogs/${blog._id}`}>Edit</Link>
                </li>
              </>
            ))}
          </ul>
        </section>
      </Container>
    </div>
  );
};

export default Blogs;
