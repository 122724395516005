import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { LuLoader2 } from "react-icons/lu";
import { approveReviewAsync } from "../../redux/reviewSlice";

const ReviewAcceptModal = ({ showModal, id }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const handleApprove = async () => {
    try {
      setLoading(true);
      await dispatch(approveReviewAsync(id)).unwrap();
      showModal(false);
    } catch (err) {
      setError(
        (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString()
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="rmodal">
      <div className="rmodal-bg" onClick={() => showModal(false)} />
      <div className="rmodal-main">
        <div className="rmodal-dialog">
          <div className="rmodal-dialog_header">
            <span className="rmodal-dialog_header--text">Approve Review</span>
          </div>
          <div className="rmodal-main">
            <p className="rmodal-main_msg">
              Are you sure you want to proceed? This action cannot be undone.
            </p>
            {error && (
              <span
                className="text-center"
                style={{
                  color: "red",
                  fontSize: "14px",
                }}
              >
                {error}
              </span>
            )}
            <div className="rmodal-main-btns">
              <button
                type="button"
                className="rmodal-main-btns--cancel"
                onClick={() => showModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="rmodal-main-btns--apr"
                onClick={handleApprove}
                disabled={loading}
              >
                {loading ? <LuLoader2 className="rmodal_spin" /> : "Approve"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewAcceptModal;
