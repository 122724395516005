import axios from "axios";
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { createBlogAsync } from "../../redux/blogSlice";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";

const AddBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [image, setBlogImage] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [toDisplay, setToDisplay] = useState(false);
  const [validateBlog, setValidateBlog] = useState(false);
  const uploadBlogImageHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post("/api/upload", formData, config);

      setBlogImage(data);
    } catch (error) {
      console.error(error);
    }
  };

  const validateBlogFields = async () => {
    if (title === "" || content === "" || image === "") {
      return false;
    } else {
      return true;
    }
  };

  const createBlog = async () => {
    const validate = await validateBlogFields();
    setValidateBlog(false);
    if (!validate) {
      setValidateBlog(true);
    } else {
      setValidateBlog(false);
      const data = {
        title,
        description,
        content,
        image,
        toDisplay,
      };
      dispatch(createBlogAsync(data));
      setTitle("");
      setBlogImage("");
      setContent("");
      setDescription("");
      setToDisplay(false);
      navigate("/blogs");
    }
  };

  const handleChange = (value) => {
    setContent(value);
  };
  return (
    <div>
      <label>Title</label>
      <input
        type="text"
        name="title"
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
        }}
      />
      {validateBlog && title.trim() === "" && (
        <small
          style={{
            color: "red",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          Title is Required
        </small>
      )}

      <textarea
        type="text"
        name="title"
        rows={4}
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      />

      <Col md={12}>
        <section className="galleryWrapper">
          <div className="center">
            <input
              type="text"
              placeholder="Please Select Image"
              style={{ display: "none" }}
            />
            <label>
              <i className="fa fa-cloud-upload"></i>
              <br />
              <span>Click to upload Image</span>
              <input
                type="file"
                style={{ display: "none" }}
                onChange={uploadBlogImageHandler}
              />
            </label>
          </div>
          {image && (
            <figure className="preview">
              <img
                src={`${process.env.REACT_APP_IMAGE_PREFIX}${image}`}
                alt=""
              />
            </figure>
          )}
          {validateBlog && image.trim() === "" && (
            <small
              style={{
                color: "red",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Image is required
            </small>
          )}

          <div>
            <label>Publish</label>
            <input
              type="checkbox"
              checked={toDisplay}
              onClick={() => setToDisplay((prev) => !prev)}
            />
          </div>
          <div>
            <ReactQuill
              value={content}
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link", "image"],
                  ["clean"],
                ],
              }}
              formats={[
                "header",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
              ]}
              onChange={handleChange}
            />
          </div>
          {validateBlog && content.trim() === "" && (
            <small
              style={{
                color: "red",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Content is required
            </small>
          )}
          <button
            className="bton bton--primary"
            onClick={(e) => {
              e.preventDefault();
              createBlog();
            }}
          >
            Create
          </button>
        </section>
      </Col>
    </div>
  );
};

export default AddBlog;
