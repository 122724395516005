import { Badge, Container, Form, Table } from "react-bootstrap";
import SelectComp from "../../components/SelectComp";
import PaginationBox from "../../components/PaginatonBox";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderHistoryAsync,
  getOrderHistoryFilterAsync,
} from "../../redux/orderSlice";
import moment from "moment";
import Paginate from "../../components/Paginate";

const OrderHistory = () => {
  // const options = [
  //   'DELIVERED',
  //   'PENDING',
  //   'PROCESSING',
  //   'CANCELLED',
  //   'ORDER_PLACED',
  //   'DISPATCHED',
  //   'ON_THE_WAY',
  // ];
  const [filterState, setFilterState] = useState(null);
  const [filterStateDate, setFilterStateDate] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { pageNumber } = useParams();
  pageNumber = pageNumber || 1;
  let { ordersHistory, orderHistoryFiltered, pagesHistory, pageHistory } =
    useSelector((state) => state.orders);
  // useEffect(() => {
  //   dispatch(getOrderHistoryAsync({ pageNumber }));
  // }, [pageNumber]);

  useEffect(() => {
    if (!filterState && !filterStateDate) {
      dispatch(getOrderHistoryAsync({ pageNumber }));
    } else {
      dispatch(
        getOrderHistoryFilterAsync({ filterState, filterStateDate, pageNumber })
      );
    }
  }, [filterState, filterStateDate, pageNumber]);
  // useEffect(() => {
  //   if (filterStateDate) {
  //     let filter = [];
  //     if (filterState) {
  //       filter = orderHistoryFiltered.filter(
  //         (i) =>
  //           moment(i.createdAt).diff(moment(Date.now()), 'days') <
  //           parseInt(filterStateDate)
  //       );
  //     } else {
  //       filter = ordersHistory.filter((i) => {
  //         // console.log(moment(i.createdAt).diff(moment(new Date()), "days"));
  //         return (
  //           moment(moment(Date.now())).diff(moment(i.createdAt), 'days') <
  //           parseInt(filterStateDate)
  //         );
  //       });
  //     }
  //     dispatch(filterOrderHistoryWithDate(filter));
  //   }
  // }, [filterStateDate]);
  return (
    <>
      <Container>
        <section>
          <h1>Order History</h1>
        </section>

        <section className="OrderHistoryWrapper">
          <section className="filters">
            <div>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  e.target.value !== "0"
                    ? setFilterState(e.target.value)
                    : setFilterState(false);
                }}
              >
                {/* <option>{options.replaceAll("_", " ")}</option> */}
                <option value="0">Status</option>
                <option value="DELIVERED">DELIVERED</option>
                <option value="CANCELLED">CANCELLED</option>
              </Form.Select>
            </div>
            <div>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  e.target.value !== "0"
                    ? setFilterStateDate(e.target.value)
                    : setFilterStateDate(false);
                }}
              >
                <option value="0">SELECT</option>
                <option value="7">Last 7 days</option>
                <option value="30">This Month</option>
                <option value="365">This Year</option>
              </Form.Select>
            </div>
            <button
              className="bton bton--nacked bton--sm"
              onClick={() => {
                setFilterState(null);
                setFilterStateDate(null);
              }}
            >
              clear Filter
            </button>
          </section>
        </section>

        <section>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Customer</th>
                <th>Email</th>
                <th>Items</th>
                <th>Price</th>
                <th>Payment</th>
                <th>Date</th>
                <th>Status</th>
                <th>Manage</th>
              </tr>
            </thead>
            <tbody>
              {ordersHistory?.map((order, i) => (
                <tr key={order._id}>
                  <td>{i + 1}</td>
                  <td>
                    {order.subscriberId
                      ? order.subscriberId?.name
                      : "GUEST CHECKOUT"}
                  </td>
                  <td>
                    {order.subscriberId
                      ? order.subscriberId?.email
                      : "GUEST CHECKOUT"}
                  </td>
                  <td>{order.orderItems.length}</td>
                  <td>AUD {parseFloat(order.grandTotal).toFixed(2)}</td>
                  <td>{order.paymentStatus}</td>
                  <td>{moment(order.createdAt).format("L")}</td>
                  <td>
                    <div>
                      {/* <Form.Select aria-label="Default select example">
                          <option>
                            {order.deliveryStatus.replaceAll("_", " ")}
                          </option>
                          {options
                            .filter((i) => i !== order.deliveryStatus)
                            .map((opt) => (
                              <option value={opt}>
                                {opt.replaceAll("_", "")}
                              </option>
                            ))}
                        </Form.Select> */}
                      <Badge bg="primary">
                        {" "}
                        {order.deliveryStatus.replaceAll("_", " ")}
                      </Badge>
                    </div>
                  </td>
                  <td>
                    <section className="ps-2">
                      <Link to={`/order-list/order-details/${order._id}`}>
                        <i
                          className="fa  fa-sliders cpr"
                          style={{ fontSize: "1.5rem" }}
                        ></i>
                      </Link>
                    </section>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Paginate
            pages={pagesHistory}
            page={pageHistory}
            isAdmin={true}
            list="order-history"
            navigate={navigate}
          />
        </section>
      </Container>
    </>
  );
};

export default OrderHistory;
