import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getBrandsAsync = createAsyncThunk(
  "brand/getBrandsAsync",
  async (_, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.get("/api/manufacturer/");

    if (response.status === 200) {
      const brands = response.data;
      return { brands };
    }
  }
);

export const createBrandAsync = createAsyncThunk(
  "brand/createBrandAsync",
  async (data, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.post("/api/manufacturer/", data, config);

    if (response.status === 201) {
      const brand = response.data;
      return { brand };
    }
  }
);

export const updateBrandAsync = createAsyncThunk(
  "brand/updateBrandAsync",
  async (data, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.put(
      `/api/manufacturer/${data.id}`,
      data,
      config
    );

    if (response.status === 200) {
      const brand = response.data;
      return { brand };
    }
  }
);

export const toggleRemovedStatusAsync = createAsyncThunk(
  "brand/toggleRemovedStatusAsync",
  async (id, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.put(
      `/api/manufacturer/toggleremovedstatus/${id}`,
      {},
      config
    );
    if (response.status === 200) {
      const togR = response.data;
      return { togR };
    }
  }
);

const brandSlice = createSlice({
  name: "brand",
  initialState: {
    brand: [],
    loading: false,
    updatingDone: false,
    brandUpdateDone: false,
    isSuccess: false,
    toggleSuccess: false,
  },
  reducers: {
    reset: (state) => {
      state.updatingDone = false;
      state.brandUpdateDone = false;
    },
    resetToggle: (state) => {
      state.toggleSuccess = false;
    },
  },
  extraReducers: {
    [getBrandsAsync.pending]: (state, action) => {
      state.loading = true;
      state.isError = false;
    },
    [getBrandsAsync.fulfilled]: (state, action) => {
      state.brand = action.payload.brands;
      state.loading = false;
      state.updatingDone = false;
    },

    [createBrandAsync.pending]: (state, action) => {
      state.loading = true;
    },

    [createBrandAsync.fulfilled]: (state, action) => {
      state.brand = [...state.brand, action.payload.brand];
      state.loading = false;
    },

    [updateBrandAsync.fulfilled]: (state, action) => {
      state.brandUpdateDone = true;
    },
    [toggleRemovedStatusAsync.fulfilled]: (state, action) => {
      state.toggleSuccess = true;
    },
  },
});

export const { reset, resetToggle } = brandSlice.actions;

export default brandSlice.reducer;
