import React, { useState } from "react";
import StarRating from "./StarRating";
import { MdVerifiedUser } from "react-icons/md";
import { formatDistance } from "date-fns";
import { BsDot } from "react-icons/bs";
import ReviewRejectCard from "./ReviewRejectCard";
import ReviewAcceptModal from "./ReviewAcceptModal";

const ReviewCard = ({ data }) => {
  const [approveModal, setApproveModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);

  return (
    <div className="reviews-item">
      <div className="rcard">
        <div className="rcard-product">
          <div className="rcard-product_img">
            <img
              src={`${process.env.REACT_APP_IMAGE_PREFIX}${data?.product?.heroImage}`}
              alt={data?.product?.name}
              className="rcard-product_img--item"
            />
          </div>
          <div className="rcard-prodict_main">
            <span className="rcard-product_title">{data?.product?.name}</span>
            <div className="rcard-product_det">
              <StarRating rating={data.rating} />
              <div className="rcard-product_row">
                <span className="rcard-product_user">
                  By {data?.user?.name} <MdVerifiedUser />
                </span>
                <BsDot className="rcard-product_dot" />
                <span className="rcard-product_date">
                  {formatDistance(new Date(data.date), new Date(), {
                    addSuffix: true,
                  })}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="rcard-main">
          <p className="rcard-text">{data.review}</p>
          {data.images && data.images.length > 0 ? (
            <div className="rcard-imgs">
              {data.images.map((entry, key) => (
                <div className="rcard-imgs_item" key={key}>
                  <img
                    src={`${process.env.REACT_APP_IMAGE_PREFIX}${entry}`}
                    alt=""
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>

        <div className="rcard-btns">
          <button
            type="button"
            className="rcard-btns_item"
            onClick={() => setApproveModal(true)}
          >
            Approve
          </button>
          <button
            type="button"
            className="rcard-btns_item"
            onClick={() => setRejectModal(true)}
          >
            Reject
          </button>
        </div>

        {rejectModal ? (
          <ReviewRejectCard showModal={setRejectModal} id={data._id} />
        ) : null}
        {approveModal ? (
          <ReviewAcceptModal showModal={setApproveModal} id={data._id} />
        ) : null}
      </div>
    </div>
  );
};

export default ReviewCard;
