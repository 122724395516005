import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getBlogAsync = createAsyncThunk("blog/getBlogAsync", async () => {
  const response = await axios.get("/api/blogs");
  if (response.status === 200) {
    const blogs = response.data;
    return { blogs };
  }
});

export const createBlogAsync = createAsyncThunk(
  "blog/createBlogAsync",
  async (data, thunkApi) => {
    const { token } = thunkApi.getState().users.user;
    const response = await axios.post("/api/blogs", data, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 201) {
      const blog = response.data;
      return { blog };
    }
  }
);

export const updateBlogAsync = createAsyncThunk(
  "blog/createBlogAsync",
  async (data, thunkApi) => {
    const { token } = thunkApi.getState().users.user;
    const response = await axios.put(`/api/blogs/${data.id}`, data, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      const res = response.data;
      return { res };
    }
  }
);

export const getBlogDetailsAsync = createAsyncThunk(
  "products/getBlogDetailsAsync",
  async (id, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.get(`/api/blogs/${id}`, config);

    if (response.status === 200) {
      const blog = response.data;

      return { blog };
    }
  }
);

const blogSlice = createSlice({
  name: "blog",
  initialState: {
    blogs: [],
    blog: null,
    loading: false,
    updateSuccess: false,
    detailsLoading: false,
    blogDetailsSuccess: false,
  },
  reducers: {
    reset: (state) => {
      state.updateSuccess = false;
    },
  },
  extraReducers: {
    [getBlogAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [getBlogAsync.fulfilled]: (state, action) => {
      state.blogs = action.payload.blogs;
      state.loading = false;
    },
    [createBlogAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [createBlogAsync.fulfilled]: (state, action) => {
      state.campaign = [...state.blogs, action.payload.blog];
      state.loading = false;
    },
    [getBlogDetailsAsync.pending]: (state, action) => {
      state.detailsLoading = true;
      state.blogDetailsSuccess = false;
    },
    [getBlogDetailsAsync.fulfilled]: (state, action) => {
      state.blogDetailsSuccess = true;
      state.blog = action.payload.blog;
      state.detailsLoading = false;
    },
    [updateBlogAsync.fulfilled]: (state, action) => {
      state.updateSuccess = true;
    },
  },
});

export const { reset } = blogSlice.actions;

export default blogSlice.reducer;
