import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Modal } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import {
  toggleRemovedStatusAsync,
  resetToggle,
  reset,
} from "../../redux/categorySlice";
import axios from "axios";
import LoaderComp from "../../components/LoaderComp";
import {
  createBrandAsync,
  getBrandsAsync,
  updateBrandAsync,
} from "../../redux/brandsSlice";

const Brands = () => {
  const dispatch = useDispatch();
  const brands = useSelector((state) => state.brands.brand);
  const loading = useSelector((state) => state.brands.loading);
  const updatingDone = useSelector((state) => state.brands.updatingDone);
  const brandUpdateDone = useSelector((state) => state.brands.brandUpdateDone);
  const toggleSuccess = useSelector((state) => state.brands.toggleSuccess);

  const [edit, setEdit] = useState(false);

  const [categoryName, setCategoryName] = useState("");
  const [brandImage, setBrandImage] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [uploading, setUploading] = useState(false);

  const [categoryId, setCategoryId] = useState("");

  const [editingId, setEditingId] = useState("");

  const [show, setShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [cateogryFieldsError, setCategoryFieldsError] = useState(false);

  useEffect(() => {
    dispatch(getBrandsAsync());
  }, [dispatch]);

  useEffect(() => {
    if (brands.length) {
      setCategoryId(brands[0]._id);
    }
  }, [brands]);

  const checkFields = async () => {
    if (categoryName.trim() === "") {
      setCategoryFieldsError(true);
      return true;
    } else {
      setCategoryFieldsError(false);
      return false;
    }
  };

  const createBrand = () => {
    const data = {
      name: categoryName,
      image: brandImage,
    };
    dispatch(createBrandAsync(data));
  };

  const updateBrand = () => {
    const data = {
      id: editingId,
      name: categoryName,
      image: brandImage,
    };
    dispatch(updateBrandAsync(data));
  };

  useEffect(() => {
    if (updatingDone) {
      dispatch(getBrandsAsync());
      dispatch(reset());
    }
  }, [dispatch, updatingDone]);

  useEffect(() => {
    if (brandUpdateDone) {
      dispatch(getBrandsAsync());
      dispatch(reset());
    }
  }, [dispatch, brandUpdateDone]);

  const toggle = (id) => {
    dispatch(toggleRemovedStatusAsync(id));
  };

  useEffect(() => {
    if (toggleSuccess) {
      dispatch(getBrandsAsync());
      dispatch(resetToggle());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, toggleSuccess]);

  const uploadBrandImageHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    const user = JSON.parse(localStorage.getItem("user"));

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post("/api/upload", formData, config);

      setBrandImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  if (loading) return <LoaderComp />;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <Container>
        <section>
          <h1>Brands</h1>
        </section>

        <section className="ManCatWrapper">
          <button
            className="bton bton--sm bton--primary"
            onClick={(e) => {
              e.preventDefault();
              setIsEditing(false);
              setEditingId("");
              setCategoryName("");
              setBrandImage("");
              handleShow();
            }}
          >
            Create Brand
          </button>

          <Row>
            {brands &&
              brands.map((brand, i) => {
                return (
                  <Col key={i} md={2}>
                    <section className="MainCatCard">
                      <figure className="MainCatCard-image">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_PREFIX}${brand.image}`}
                          alt=""
                        />
                        <figcaption>
                          <div className="MainCatCard-text">{brand.name}</div>
                          <hr />
                          <div className="flexBetween mt-3">
                            <Form>
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                onClick={() => {
                                  toggle(brand._id);
                                }}
                                checked={!brand.removedStatus ? true : false}
                              />
                            </Form>

                            <div
                              className="cpntr"
                              onClick={(e) => {
                                e.preventDefault();
                                setIsEditing(true);
                                setEditingId(brand._id);
                                setCategoryName(brand.name);
                                setBrandImage(brand.image);
                                setShow(true);
                              }}
                            >
                              Edit <i className="fa fa-edit ms-2 "></i>
                            </div>
                          </div>
                        </figcaption>
                      </figure>
                    </section>
                  </Col>
                );
              })}
          </Row>
        </section>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{isEditing ? "Edit" : "Add"} Brand</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Brand Name</Form.Label>
                <Form.Control
                  type="text"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                />
                {cateogryFieldsError && categoryName.trim() === "" && (
                  <small style={{ color: "red" }}>Brand Name is required</small>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Brand Image</Form.Label>
                <Form.Control type="file" onChange={uploadBrandImageHandler} />
                {cateogryFieldsError && brandImage.trim() === "" && (
                  <small style={{ color: "red" }}>Image is required</small>
                )}
              </Form.Group>
              <span style={{ fontSize: "12px" }}>(*Max size 2 MB)</span>
              {brandImage !== "" && (
                <figure className="preview">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_PREFIX}${brandImage}`}
                    alt=""
                  />
                </figure>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={async (e) => {
                e.preventDefault();
                const isValid = await checkFields();

                if (!isValid) {
                  if (isEditing) {
                    updateBrand();
                  } else {
                    createBrand();
                  }
                  handleClose();
                }
              }}
              className="bton bton--sm bton--primary"
            >
              {!isEditing ? "Add" : "Edit"}
            </button>
            <button
              className="bton bton--sm bton--ghost--danger"
              onClick={handleClose}
            >
              cancel
            </button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default Brands;
