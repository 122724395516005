import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Table,
  Modal,
  CloseButton,
} from "react-bootstrap";

import PaginationBox from "../../components/PaginatonBox";
import SelectComp from "../../components/SelectComp";
import Tooltip from "react-simple-tooltip";

import { useSelector, useDispatch } from "react-redux";
import {
  createSubCategoryAsync,
  getCategoriesAsync,
  createSubCategory2Async,
  updateSubCategoryAsync,
  createCategoryAsync,
  updateCategoryAsync,
  toggleRemovedStatusAsync,
  resetToggle,
  reset,
} from "../../redux/categorySlice";
import axios from "axios";
import LoaderComp from "../../components/LoaderComp";

const Categories = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.category);
  const loading = useSelector((state) => state.categories.loading);
  const creatingSub = useSelector((state) => state.categories.creatingSub);
  const creatingSub2 = useSelector((state) => state.categories.creatingSsub);
  const updatingDone = useSelector((state) => state.categories.updatingDone);
  const catUpdateDone = useSelector((state) => state.categories.catUpdateDone);
  const toggleSuccess = useSelector((state) => state.categories.toggleSuccess);

  const [edit, setEdit] = useState(false);

  const [categoryName, setCategoryName] = useState("");
  const [categoryImage, setCategoryImage] = useState("");
  const [seoTitle, setSeoTitle] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const [seoKeywords, setSeoKeywords] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [uploading, setUploading] = useState(false);

  const [emptySubField, setEmptySubField] = useState(false);
  const [subCat2FieldEmpty, setSubCat2FieldEmpty] = useState(false);

  const [subcategory, setSubcategory] = useState("sub");
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryName, setSubcategoryName] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [subCategory2Name, setSubcategory2Name] = useState("");

  const [subCategoryList, setSubCateogryList] = useState([]);

  const [editingId, setEditingId] = useState("");

  const [editCategory, setEditCategory] = useState("");
  const [editSubcategoryList, setEditSubCategoryList] = useState([]);
  const [editSubcategoryName, setEditSubcategoryName] = useState("");
  const [editSubcategoryId, setEditSubcategoryId] = useState("");
  const [editSubcat2List, setEditSubcat2List] = useState([]);
  const [show, setShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [cateogryFieldsError, setCategoryFieldsError] = useState(false);

  useEffect(() => {
    dispatch(getCategoriesAsync());
  }, [dispatch]);

  useEffect(() => {
    if (categories && categories.length) {
      setCategoryId(categories[0]._id);
    }
  }, [categories]);

  const checkFields = async () => {
    if (categoryName.trim() === "") {
      setCategoryFieldsError(true);
      return true;
    } else {
      setCategoryFieldsError(false);
      return false;
    }
  };

  const createCategory = () => {
    const data = {
      name: categoryName,
      image: categoryImage,
      seoTitle,
      seoDescription,
      keywords: seoKeywords,
    };
    dispatch(createCategoryAsync(data));

    // dispatch(getCategoriesAsync());
  };

  const updateCategory = () => {
    const data = {
      id: editingId,
      name: categoryName,
      image: categoryImage,
      seoTitle,
      seoDescription,
      keywords: seoKeywords,
    };
    dispatch(updateCategoryAsync(data));
  };

  useEffect(() => {
    if (updatingDone) {
      dispatch(getCategoriesAsync());
      dispatch(reset());
    }
  }, [dispatch, updatingDone]);

  useEffect(() => {
    if (catUpdateDone) {
      dispatch(getCategoriesAsync());
      dispatch(reset());
    }
  }, [dispatch, catUpdateDone]);

  const toggle = (id) => {
    dispatch(toggleRemovedStatusAsync(id));
  };

  useEffect(() => {
    if (toggleSuccess) {
      dispatch(getCategoriesAsync());
      dispatch(resetToggle());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, toggleSuccess]);

  const uploadCategoryImageHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);
    const user = JSON.parse(localStorage.getItem("user"));

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post("/api/upload", formData, config);

      setCategoryImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  if (loading) return <LoaderComp />;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <Container>
        <section>
          <h1>Categories</h1>
        </section>

        <section className="ManCatWrapper">
          <button
            className="bton bton--sm bton--primary"
            onClick={(e) => {
              e.preventDefault();
              setIsEditing(false);
              setEditingId("");
              setCategoryName("");
              setSeoTitle("");
              setSeoDescription("");
              setSeoKeywords("");
              setCategoryImage("");
              handleShow();
            }}
          >
            Create Main Category
          </button>

          <Row>
            {categories &&
              categories.map((category, i) => {
                return (
                  <Col key={i} md={2}>
                    <section className="MainCatCard">
                      <figure className="MainCatCard-image">
                        {/* <img
                          src={`${process.env.REACT_APP_IMAGE_PREFIX}${category.image}`}
                          alt=""
                        /> */}
                        <figcaption>
                          <div className="MainCatCard-text">
                            {category.name}
                          </div>
                          <hr />
                          <div className="flexBetween mt-3">
                            <Form>
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                onClick={() => {
                                  toggle(category._id);
                                }}
                                checked={!category.removedStatus ? true : false}
                              />
                            </Form>

                            <div
                              className="cpntr"
                              onClick={(e) => {
                                e.preventDefault();
                                setIsEditing(true);
                                setEditingId(category._id);
                                setCategoryName(category.name);
                                setSeoTitle(category.seoTitle);
                                setSeoDescription(category.seoDescription);
                                setSeoKeywords(category.keywords);
                                setCategoryImage(category.image);
                                setShow(true);
                              }}
                            >
                              Edit <i className="fa fa-edit ms-2 "></i>
                            </div>
                          </div>
                        </figcaption>
                      </figure>
                    </section>
                  </Col>
                );
              })}
          </Row>
        </section>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{isEditing ? "Edit" : "Add"} Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Category Name</Form.Label>
                <Form.Control
                  type="text"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                />
                {cateogryFieldsError && categoryName.trim() === "" && (
                  <small style={{ color: "red" }}>
                    Category Name is required
                  </small>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>SEO Title</Form.Label>
                <Form.Control
                  type="text"
                  value={seoTitle}
                  onChange={(e) => setSeoTitle(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>SEO description</Form.Label>
                <Form.Control
                  type="text"
                  value={seoDescription}
                  onChange={(e) => setSeoDescription(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>SEO Keyword(seprated with comma)</Form.Label>
                <Form.Control
                  type="text"
                  value={seoKeywords}
                  onChange={(e) => setSeoKeywords(e.target.value)}
                />
              </Form.Group>
              {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Category Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={uploadCategoryImageHandler}
                />
                {cateogryFieldsError && categoryImage.trim() === "" && (
                  <small style={{ color: "red" }}>Image is required</small>
                )}
              </Form.Group> */}

              {/* {categoryImage !== "" && (
                <figure className="preview">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_PREFIX}${categoryImage}`}
                    alt=""
                  />
                </figure>
              )} */}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={async (e) => {
                e.preventDefault();
                const isValid = await checkFields();

                if (!isValid) {
                  if (isEditing) {
                    updateCategory();
                  } else {
                    createCategory();
                  }
                  handleClose();
                }
              }}
              className="bton bton--sm bton--primary"
            >
              {!isEditing ? "Add" : "Edit"}
            </button>
            <button
              className="bton bton--sm bton--ghost--danger"
              onClick={handleClose}
            >
              cancel
            </button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default Categories;
