import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

//components

import SideBar from "../../components/Sidebar";
import NavBar from "../../components/Navbar";

//pages

import DashBoard from "../Dashboard";
import OrderPage from "../OrderPage";
import OrderDetails from "../OrderDetails";
import OrderHistory from "../OrderHistory";
import Categories from "../Categories";
import AddProduct from "../AddProduct";
import Products from "../Products";
import { Carousel } from "bootstrap";
import Slider from "../Slider";
import Register from "../Register";
import Transactions from "../Transactions";
import Reviews from "../Reviews";
import Customers from "../Customers";
import Login from "../Login";
import axios from "axios";
import EditProduct from "../EditProduct";
import BrandLogo from "../../assets/images/brand_image.png";
import Enquiries from "../Enquiries";
import ProductReviews from "../productReviews";
import Brands from "../Brands";
import Blogs from "../Blogs";
import AddBlog from "../AddBlog";
import EditBlog from "../EditBlog";

const App = () => {
  axios.defaults.baseURL = "https://backend.everestkeys.com.au";
  // axios.defaults.baseURL = "http://localhost:8010";
  const location = useLocation();
  return (
    <>
      <section className="projectWrapper">
        {location.pathname !== "/login" && (
          <aside className="sidebarWrapper">
            <div className="sidebarWrapper-brand">
              <img src={BrandLogo} alt="" />
            </div>

            <SideBar />
          </aside>
        )}
        <section className="contentWrapper">
          {location.pathname !== "/login" && <NavBar />}
          <Routes>
            <Route path="/" element={<DashBoard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/order-list" element={<OrderPage />} />
            <Route path="/order-list/:pageNumber" element={<OrderPage />} />
            <Route path="/order-history" element={<OrderHistory />} />
            <Route
              path="/order-history/:pageNumber"
              element={<OrderHistory />}
            />
            <Route path="/categories" element={<Categories />} />
            <Route path="/brands" element={<Brands />} />
            <Route path="/add-products" element={<AddProduct />} />
            <Route path="/edit-product/:id" element={<EditProduct />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/:pageNumber" element={<Products />} />
            <Route path="/carousel" element={<Slider />} />
            <Route path="/register" element={<Register />} />
            <Route path="/register/:pageNumber" element={<Register />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/reviews/:pageNumber" element={<Reviews />} />
            <Route path="/blogs/add" element={<AddBlog />} />
            <Route path="/blogs/:id" element={<EditBlog />} />
            {/* <Route path="/reviews/:id" element={<ProductReviews />} /> */}
            <Route path="/customers" element={<Customers />} />
            <Route path="/enquiries" element={<Enquiries />} />
            <Route path="/enquiries/:pageNumber" element={<Enquiries />} />
            <Route path="/customers/:pageNumber" element={<Customers />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route
              path="/order-list/order-details/:id"
              element={<OrderDetails />}
            />
          </Routes>
        </section>
      </section>
    </>
  );
};

export default App;
