import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/userSlice";
import { MdOutlineReviews } from "react-icons/md";
import {
  fetchPendingReviews,
  getPendingCount,
  getPendingReview,
} from "../../redux/reviewSlice";

const Index = () => {
  const { user } = useSelector((state) => state.users);
  const reviews = useSelector(getPendingReview);
  const count = useSelector(getPendingCount);

  useEffect(() => {
    if (!reviews) {
      dispatch(fetchPendingReviews(1));
    }
  }, []);

  const dispatch = useDispatch();
  return (
    <div>
      <ul className="navList">
        <li className="navList-items  ">
          <NavLink
            className={(navData) =>
              navData.isActive ? "activeSidebar" : "notActive"
            }
            to="/"
          >
            <i className="fa  fa-th-large"></i> &nbsp; Dashboard
          </NavLink>
        </li>
        <li className="navList-items">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {" "}
                <i
                  className="fa  fa-tasks
"
                ></i>{" "}
                &nbsp; Order Center
              </Accordion.Header>
              <Accordion.Body>
                {" "}
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "activeSidebar" : "notActive"
                  }
                  to="/order-list"
                >
                  <i className="fa  fa-list"></i>
                  &nbsp; Order List
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "activeSidebar" : "notActive"
                  }
                  to="/order-history"
                >
                  <i
                    className="fa  fa-history
"
                  ></i>
                  &nbsp; Order History
                </NavLink>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </li>
        <li className="navList-items">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {" "}
                <i className="fa   fa-cube"></i> &nbsp; Products
              </Accordion.Header>
              <Accordion.Body>
                {" "}
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "activeSidebar" : "notActive"
                  }
                  to="/categories"
                >
                  <i className="fa  fa-cubes"></i>&nbsp; Categories
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "activeSidebar" : "notActive"
                  }
                  to="/brands"
                >
                  <i className="fa  fa-cubes"></i>&nbsp; Brands
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "activeSidebar" : "notActive"
                  }
                  to="/add-products"
                >
                  <i className="fa fa-plus-square-o"></i>&nbsp; Add Product
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "activeSidebar" : "notActive"
                  }
                  to="/products"
                >
                  <i className="fa fa-list-ul"></i>&nbsp; Product List
                </NavLink>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </li>
        <li className="navList-items  ">
          <NavLink
            className={(navData) =>
              navData.isActive ? "activeSidebar" : "notActive"
            }
            to="/carousel"
          >
            <i className="fa fa-image"></i> &nbsp; Campaigns
          </NavLink>
        </li>{" "}
        <li className="navList-items  ">
          <NavLink
            className={(navData) =>
              navData.isActive ? "activeSidebar" : "notActive"
            }
            to="/blogs"
          >
            <i className="fa fa-image"></i> &nbsp; Blogs
          </NavLink>
        </li>{" "}
        {user?.isSuperAdmin && (
          <li className="navList-items  ">
            <NavLink
              className={(navData) =>
                navData.isActive ? "activeSidebar" : "notActive"
              }
              to="/register"
            >
              <i className="fa fa-user-plus"></i> &nbsp; Register
            </NavLink>
          </li>
        )}
        {/* <li className="navList-items  ">
          <NavLink
            className={(navData) =>
              navData.isActive ? "activeSidebar" : "notActive"
            }
            to="/reviews"
          >
            <MdOutlineReviews className="review-icon" /> &nbsp; Reviews{" "}
            {count && count > 0 ? (
              <span style={{ fontSize: "12px" }}>({count})</span>
            ) : null}
          </NavLink>
        </li>{" "} */}
        <li className="navList-items  ">
          <NavLink
            className={(navData) =>
              navData.isActive ? "activeSidebar" : "notActive"
            }
            to="/customers"
          >
            <i className="fa fa-user-circle"></i> &nbsp; Customers
          </NavLink>
        </li>{" "}
        <li className="navList-items  ">
          <NavLink
            className={(navData) =>
              navData.isActive ? "activeSidebar" : "notActive"
            }
            to="/enquiries"
          >
            <i className="fa fa-user-circle"></i> &nbsp; Enquiries
          </NavLink>
        </li>{" "}
        {/* <li className='navList-items  '>
          <NavLink
            className={(navData) =>
              navData.isActive ? 'activeSidebar' : 'notActive'
            }
            to='/reviews'
          >
            <i className='fa  fa-file-text'></i> &nbsp; Reviews
          </NavLink>
        </li>{' '} */}
        {/* <li className="navList-items  ">
          <NavLink
            className={(navData) =>
              navData.isActive ? "activeSidebar" : "notActive"
            }
            to="/transactions"
          >
            <i className="fa fa-television"></i> &nbsp; Transactions
          </NavLink>
        </li>
        <li className="navList-items  ">
          <NavLink
            className={(navData) =>
              navData.isActive ? "activeSidebar" : "notActive"
            }
            to="/reports"
          >
            <i className="fa fa-television"></i> &nbsp; Reports
          </NavLink>
        </li> */}
        <li className="navList-items  ">
          <NavLink
            onClick={() => {
              dispatch(logout());
            }}
            className={(navData) =>
              navData.isActive ? "activeSidebar" : "notActive"
            }
            to="/login"
          >
            <i className="fa fa-sign-out"></i> &nbsp; Logout
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Index;
