import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import ReviewCard from "../../components/reviews/ReviewCard";
import Paginate from "../../components/Paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPendingReviews,
  getFetchPendingReviewError,
  getFetchPendingReviewLoading,
  getPendingCount,
  getPendingPages,
  getPendingReview,
} from "../../redux/reviewSlice";
import LoaderComp from "../../components/LoaderComp";

import { useParams, useNavigate } from "react-router-dom";

const Reviews = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const data = useSelector(getPendingReview);
  const pages = useSelector(getPendingPages);
  const count = useSelector(getPendingCount);
  const pageNumber = useParams().pageNumber || 1;

  const loading = useSelector(getFetchPendingReviewLoading);
  const error = useSelector(getFetchPendingReviewError);

  useEffect(() => {
    dispatch(fetchPendingReviews(pageNumber));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  if (loading) {
    return <LoaderComp />;
  }

  if (error) {
    return (
      <p
        style={{
          color: "red",
          fontSize: "14px",
          textAlign: "center",
        }}
      >
        Error: {error}
      </p>
    );
  }

  return (
    <Container>
      <h1>Reviews ({count})</h1>
      <div className="reviews">
        {data && data.length > 0 ? (
          data.map((entry) => <ReviewCard data={entry} />)
        ) : (
          <div className="reviews-item">
            <div className="reviews-empty">
              <div className="reviews-empty_main">
                <p>
                  There are no reviews pending to be moderated. Please check
                  later for new submissions.
                </p>
              </div>
            </div>
          </div>
        )}
        {pages && pages > 0 ? (
          <div className="reviews-pag">
            <Paginate
              pages={pages}
              page={Number(pageNumber)}
              isAdmin={true}
              list="reviews"
              navigate={navigate}
            />
          </div>
        ) : null}
      </div>
    </Container>
  );
};

export default Reviews;
