import { configureStore } from "@reduxjs/toolkit";
import productsReducer from "./productsSlice";
import categoriesReducer from "./categorySlice";
import brandsReducer from "./brandsSlice";
import carouselReducer from "./carouselSilce";
import userReducer from "./userSlice";
import orderReducer from "./orderSlice";
import promoReducer from "./promoSlice";
import notificationReducer from "./notificationSlice";
import reviewReducer from "./reviewSlice";
import blogReducer from "./blogSlice";

export default configureStore({
  reducer: {
    products: productsReducer,
    categories: categoriesReducer,
    brands: brandsReducer,
    carousels: carouselReducer,
    users: userReducer,
    orders: orderReducer,
    promos: promoReducer,
    notification: notificationReducer,
    review: reviewReducer,
    blogs: blogReducer,
  },
});
