import React, { useState } from 'react';
import { Col, Container, Row, Form, Table } from 'react-bootstrap';
import PaginationBox from '../../components/PaginatonBox';
import ModalComponent from '../../components/ModalComponent';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  editUserAsync,
  getUserListAsync,
  registerUser,
} from '../../redux/userSlice';
import Message from '../../components/Message/Message';
// import {
//   getPromoCodesAsync,
//   addPromoAsync,
//   togglePromoCodeStatusAsync,
//   reset,
//   deletePromoCodeAsync,
// } from '../../redux/promoSlice';
import Paginate from '../../components/Paginate';
import { useParams } from 'react-router-dom';

const Register = () => {
  const [editForm, setEditForm] = useState(null);
  const [user, setUser] = useState(null);
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [existsError, setExistsError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [exist, setExist] = useState(null);
  const [loading, setLoading] = useState(null);
  let { pageNumber } = useParams();
  pageNumber = pageNumber || 1;
  // const {
  //   promos,
  //   loading: promoLoading,
  //   toggleSuccess,
  // } = useSelector((state) => state.promos);

  // const [promoState, setPromoState] = useState({
  //   code: null,
  //   discountPercent: null,
  //   maxDiscount: null,
  // });

  // const onChange = (e) =>
  //   setPromoState({ ...promoState, [e.target.name]: e.target.value });

  // const { code, discountPercent, maxDiscount } = promoState;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   dispatch(getPromoCodesAsync());
  // }, [dispatch]);

  // const onPromoSubmit = async (e) => {
  //   dispatch(addPromoAsync(promoState));
  // };

  // const toggle = (id) => {
  //   dispatch(togglePromoCodeStatusAsync(id));
  // };

  // useEffect(() => {
  //   if (toggleSuccess) {
  //     dispatch(getPromoCodesAsync());
  //     dispatch(reset());
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch, toggleSuccess]);

  // useEffect(() => {
  //   if (!promoLoading) {
  //     setPromoState({ code: null, discountPercent: null, maxDiscount: null });
  //   }
  // }, [promoLoading]);

  const validation = (array) => {
    return array.some((i) => i === null || i === '');
  };
  const { token } = useSelector((state) => state.users.user);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const {
    isLoading,
    users,
    user: admin,
    isError,
    pages,
    page,
  } = useSelector((state) => state.users);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (validation([name, phone, email, password])) {
      setError(true);
      setLoading(false);
      return;
    } else if (!emailPattern.test(email)) {
      setEmailError(true);
      setLoading(false);
      return;
    } else {
      dispatch(getUserListAsync());
      const rm_phones = users.map((rm) => rm.phone);
      const rm_emails = users.map((rm) => rm.email);
      if (rm_phones.includes(phone)) {
        setExistsError(true);
        setExist(phone);
        setLoading(false);
        return;
      } else if (rm_emails.includes(email)) {
        setExistsError(true);
        setExist(email);
        setLoading(false);
        return;
      } else {
        dispatch(
          registerUser({
            user: { name, phone, email, password, isAdmin: true },
            config,
          })
        );
        setLoading(false);
      }
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(user.email)) {
      setEmailError(true);
      setLoading(false);
      return;
    } else {
      dispatch(getUserListAsync());
      const user_phones = users
        .map((user) => user.phone)
        .filter((i) => i !== user.phone);
      const user_emails = users
        .map((user) => user.email)
        .filter((i) => i !== user.email);
      if (user_phones.includes(user.phone)) {
        setExistsError(true);
        setExist(user.phone);
        setLoading(false);
        return;
      } else if (user_emails.includes(user.email)) {
        setExistsError(true);
        setExist(user.email);
        setLoading(false);
        return;
      } else {
        dispatch(editUserAsync({ user, config }));
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (!admin.isSuperAdmin) {
      navigate('/');
    } else {
      dispatch(getUserListAsync({ pageNumber }));
    }
  }, [pageNumber]);
  const { isRegisterSuccess, isUpdateSuccess, isDeleteSuccess } = useSelector(
    (state) => state.users
  );
  useEffect(() => {
    isRegisterSuccess && dispatch(getUserListAsync({ pageNumber }));
  }, [isRegisterSuccess]);
  useEffect(() => {
    if (isUpdateSuccess || isDeleteSuccess) {
      dispatch(getUserListAsync());
      setEditForm(null);
    }
  }, [isUpdateSuccess, isDeleteSuccess]);
  useEffect(() => {
    if (isRegisterSuccess || isUpdateSuccess || isDeleteSuccess) {
      setName('');
      setPhone('');
      setEmail('');
      setPassword('');
      setTimeout(() => {
        setSuccess(null);
      }, 2000);
    }
  }, [isRegisterSuccess, isUpdateSuccess, isDeleteSuccess]);

  return (
    <>
      <Container>
        <section>
          <h1>Register Admin</h1>
        </section>
        <section>
          {error && (
            <Message success={false} setError={setError}>
              Please Fill out all the inputs and try again.
            </Message>
          )}
          {success && (
            <Message success={true} setError={setSuccess}>
              Registered Successfully!
            </Message>
          )}
          {emailError && (
            <Message success={false} setError={setEmailError}>
              Please enter a valid email!
            </Message>
          )}
          {existsError && (
            <Message success={false} setError={setExistsError}>
              {exist} already exists!
            </Message>
          )}
          <Row>
            <Col md={4}>
              {!editForm ? (
                <section className='createadminWrapper'>
                  <h2>Create an Account</h2>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <Form.Group
                          className='mb-3 mt-4'
                          controlId='formBasicEmail'
                        >
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter full name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group
                          className='mb-3 mt-4'
                          controlId='formBasicEmail'
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type='email'
                            placeholder='Enter email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group
                          className='mb-3 mt-4'
                          controlId='formBasicEmail'
                        >
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter phone'
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group
                          className='mb-3 mt-4'
                          controlId='formBasicEmail'
                        >
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type='password'
                            placeholder='Password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <button
                      className='bton bton--primary bton--full'
                      type='submit'
                    >
                      Create Account
                    </button>
                  </Form>
                </section>
              ) : (
                <section className='createadminWrapper'>
                  <h2>Edit Account</h2>
                  <Form onSubmit={handleEdit}>
                    <Row>
                      <Col md={12}>
                        <Form.Group
                          className='mb-3 mt-4'
                          controlId='formBasicEmail'
                        >
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter full name'
                            value={user.name}
                            name='name'
                            onChange={(e) =>
                              setUser({ ...user, name: e.target.value })
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group
                          className='mb-3 mt-4'
                          controlId='formBasicEmail'
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type='email'
                            placeholder='Enter email'
                            value={user.email}
                            name='email'
                            onChange={(e) =>
                              setUser({ ...user, email: e.target.value })
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group
                          className='mb-3 mt-4'
                          controlId='formBasicEmail'
                        >
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter phone'
                            value={user.phone}
                            name='phone'
                            onChange={(e) =>
                              setUser({ ...user, phone: e.target.value })
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group
                          className='mb-3 mt-4'
                          controlId='formBasicEmail'
                        >
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type='password'
                            placeholder='Password'
                            name='password'
                            onChange={(e) =>
                              setUser({ ...user, password: e.target.value })
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <button
                      className='bton bton--primary bton--full'
                      type='submit'
                    >
                      Update Account
                    </button>
                    <button
                      className='bton bton--ghost--danger bton--full mt-2'
                      type='submit'
                      onClick={(e) => {
                        setEditForm(false);
                      }}
                    >
                      Discard Changes
                    </button>
                  </Form>
                </section>
              )}
              {/* PROMO CODES SECTION STARTS HERE */}
              {/* <section className='promoWrappper'>
                <h1>Promo Codes</h1>

                <section className='promoConatiner'>
                  <label>Enter new PromoCode and %</label>
                  <section className='promoConatiner-inputs'>
                    <input
                      placeholder='code'
                      type='text'
                      name='code'
                      value={code}
                      onChange={onChange}
                    />
                    <input
                      placeholder='%'
                      type='text'
                      name='discountPercent'
                      value={discountPercent}
                      onChange={onChange}
                    />{' '}
                    <input
                      placeholder='Max Discount'
                      type='text'
                      name='maxDiscount'
                      value={maxDiscount}
                      onChange={onChange}
                    />{' '}
                    <button
                      className='bton bton--md bton--secondary'
                      onClick={(e) => {
                        e.preventDefault();
                        onPromoSubmit();
                      }}
                    >
                      {' '}
                      +
                    </button>
                  </section>
                  <ul className='promoConatiner-list'>
                    {promos &&
                      promos.map((promo) => {
                        return (
                          <li>
                            <span>
                              {' '}
                              <strong className=''>{promo.code}</strong>{' '}
                              <span className='csu'>
                                {' '}
                                {promo.discountPercent}%{' '}
                              </span>
                              up to
                              <span className='cwa'>
                                {' '}
                                ${promo.maxDiscount}{' '}
                              </span>
                            </span>{' '}
                            <span style={{ display: 'flex' }}>
                              <span className='p-2'>
                                <Form.Check
                                  type='switch'
                                  id='custom-switch'
                                  onClick={() => {
                                    toggle(promo._id);
                                  }}
                                  checked={promo.active ? true : false}
                                />{' '}
                              </span>
                              <span
                                className='p-2 cpntr'
                                onClick={() => {
                                  dispatch(deletePromoCodeAsync(promo._id));
                                }}
                              >
                                <i className='fa fa-trash cda  '></i>{' '}
                              </span>
                            </span>
                          </li>
                        );
                      })}
                  </ul>
                </section>
              </section> */}

              {/* PROMO CODES SECTION ENDS HERE */}
            </Col>

            {/* -----------------------------------------------User List---------------------------------------------------------- */}
            <Col md={8}>
              <section className='adminWrapper'>
                <h2>Registered Admin list</h2>

                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.N</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users && users.length > 0 ? (
                      users.map((user, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{user.phone}</td>
                          <td>
                            <i
                              onClick={(e) => {
                                setUser(user);
                                setEditForm(true);
                              }}
                              className='fa fa-edit cpntr mt-1'
                              style={{ fontSize: '1.3rem' }}
                            ></i>
                            {/*  */}
                            <ModalComponent
                              message='Are You sure want to delete this admin ?'
                              action1='cancel'
                              action2='delete'
                              Title={user.name}
                              button='del'
                              classNAME=' bton bton--sm bton--nacked  ms-2'
                              id={user._id}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div className='text-center mt-3 cwa'>
                        <p>No registered admins!</p>
                      </div>
                    )}
                  </tbody>
                </Table>

                {/* {users && ( */}
                <Paginate
                  page={page}
                  pages={pages}
                  isAdmin={true}
                  list='register'
                  navigate={navigate}
                />
                {/* )} */}
              </section>
            </Col>
          </Row>
        </section>
      </Container>
    </>
  );
};

export default Register;
